import type { AttributePayload } from '@/store/types'
import { post, get } from '@/backend/api'
import { parseError, errorMessages, isErrorResponse, type ParsedError } from '@/backend/error'
import type { WindAuthAction } from './WindAuthAction'
import type { AxiosResponse } from 'axios'

export type CreateOrFetchAnnotationAttributeParams = {
  classId: number
  color: string
  name: string
}

export const createOrFetchAnnotationAttribute = async (
  params: CreateOrFetchAnnotationAttributeParams,
): Promise<AxiosResponse | ParsedError> => {
  const { classId, name, color } = params
  const path = `annotation_classes/${classId}/attributes`
  const attrs = { name, color }
  try {
    const response = await post<AttributePayload>(path, attrs)
    return response
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.ATTRIBUTE_CREATE)
  }
}

export type LoadClassAnnotationAttributesParams = { classId: number }

export const loadClassAnnotationAttributes = async (
  params: LoadClassAnnotationAttributesParams,
): Promise<AxiosResponse | ParsedError> => {
  const path = `annotation_classes/${params.classId}/attributes`
  try {
    const response = await get<AttributePayload[]>(path)
    return response
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error)
  }
}

export type LoadDatasetAnnotationAttributesParams = { teamSlug: string }

export const loadDatasetAnnotationAttributes = async (
  params: LoadDatasetAnnotationAttributesParams,
): Promise<AxiosResponse | ParsedError> => {
  const path = `teams/${params.teamSlug}/attributes`
  try {
    const response = await get<AttributePayload[]>(path)
    return response
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error)
  }
}

export type LoadWorkflowActionsParams = {
  datasetItemId: number
}

export type CreateWindAuthParams = {
  action: WindAuthAction
  teamId: number
}

export const createWindAuth = async (
  params: CreateWindAuthParams,
): Promise<AxiosResponse | ParsedError> => {
  const { action, teamId } = params
  const path = `teams/${teamId}/wind_auth`
  const payload = { action }
  try {
    const response = await post<{ token: string }>(path, payload)
    return response
  } catch (error) {
    if (!isErrorResponse(error)) {
      throw error
    }
    return parseError(error, errorMessages.WIND_AUTH_CREATE)
  }
}

export { confirm2fa } from './confirm2fa'
export * from './createAnnotationClass'
export { createUploadInfo } from './createUploadInfo'

export { loadBillingInfo } from './loadBillingInfo'
export { loadClassUsage } from './loadClassUsage'
export { loadDataset } from './loadDataset'
export { loadV2DatasetItemTimeSummary } from './loadV2DatasetItemTimeSummary'
export { loadTeamMemberships } from './loadTeamMemberships'
export { login } from './login'
export { login2fa } from './login2fa'
export { loginSSO } from './loginSSO'
export { setup2fa } from './setup2fa'
export { updateTeamUsageLimit } from './updateTeamUsageLimit'

export { getStorages } from './getStorages'
export { createStorage } from './createStorage'
export { deleteStorage } from './deleteStorage'
export { updateStorage } from './updateStorage'
export { setStorageAsDefault } from './setStorageAsDefault'

// dataset item reports
export { createDatasetItemReport } from './createDatasetItemReport'
export { deleteDatasetItemReport } from './deleteDatasetItemReport'
export { loadDatasetItemReport } from './loadDatasetItemReport'
export { loadDatasetItemReports } from './loadDatasetItemReports'

// v2 items
export { archiveV2Items } from './archiveV2Items'
export { addPriorityToV2Items } from './addPriorityToV2Items'

export { deleteV2Annotations } from './deleteV2Annotations'
export { deleteV2Items } from './deleteV2Items'
export { exportV2Dataset } from './exportV2Dataset'
export { loadAnnotation } from './loadAnnotation'
export { loadV2Annotations } from './loadV2Annotations'
export { loadV2AnnotationsSnapshot } from './loadV2AnnotationsSnapshot'
export { loadV2DatasetItems } from './loadV2DatasetItems'
export { loadV2ItemsStageCounts } from './loadV2ItemsStageCounts'
export { moveV2ItemsToPath } from './moveV2ItemsToPath'
export { restoreV2Items } from './restoreV2Items'
export { tagDatasetItemsV2 } from './tagDatasetItemsV2'
export { untagDatasetItemsV2 } from './untagDatasetItemsV2'

// v2 workflows
export { assignV2Items } from './assignV2Items'
export { sendV2Commands } from './sendV2Commands'
export { setV2Stage } from './setV2Stage'
export { copyV2Annotations } from './copyV2Annotations'
export { loadWebhookResponses } from './loadWebhookResponses'
export { createWebhookTest } from './createWebhookTest'

// v2 comments
export { createV2Comment } from './createV2Comment'
export { createV2CommentThread } from './createV2CommentThread'
export { deleteV2Comment } from './deleteV2Comment'
export { deleteV2CommentThread } from './deleteV2CommentThread'
export { loadV2Comments } from './loadV2Comments'
export { loadV2CommentThreads } from './loadV2CommentThreads'
export { updateV2Comment } from './updateV2Comment'
export { updateV2CommentThread } from './updateV2CommentThread'
