<template>
  <Portal :to="fullscreen ? 'fullscreen' : 'mainContent'">
    <Transition name="fade">
      <div
        v-if="open"
        class="darwin-dialog"
        :class="{
          'darwin-dialog--with-overlay': showOverlay,
          'darwin-dialog--with-no-overlay': !showOverlay,
          'darwin-dialog--scrollable': scrollable,
          [className]: !!className,
        }"
        :style="wrapperStyle"
        v-bind="$attrs"
        role="dialog"
      >
        <div
          ref="modalRef"
          class="darwin-dialog__content"
          :style="contentStyle"
        >
          <slot />
        </div>
      </div>
    </Transition>
  </Portal>
</template>

<script lang="ts">
import type { MaybeElement, MaybeElementRef } from '@vueuse/core'
import { onClickOutside } from '@vueuse/core'
import type { PropType } from 'vue'
import { computed, defineComponent, ref, onMounted, onBeforeUnmount } from 'vue'

import { containsCssUnit } from '@/uiKit/utils/string'

export default defineComponent({
  name: 'DarwinDialog',
  props: {
    open: { default: false, type: Boolean },
    clickOutsideToClose: { default: true, type: Boolean },
    escToClose: { default: true, type: Boolean },
    showOverlay: { default: true, type: Boolean },
    nudgeTop: { default: 0, type: [String, Number] },
    nudgeRight: { default: 0, type: [String, Number] },
    nudgeBottom: { default: 0, type: [String, Number] },
    nudgeLeft: { default: 0, type: [String, Number] },
    maxHeight: { default: 360, type: [String, Number] },
    minHeight: { default: 30, type: [String, Number] },
    maxWidth: { type: [Number, String], default: undefined },
    minWidth: { type: [Number, String], default: undefined },
    scrollable: { default: true, type: Boolean },
    fullscreen: { default: true, type: Boolean },
    className: { default: undefined, type: String },
    ignoreClickOutsideFrom: {
      default: () => [],
      type: Array as PropType<string[] | MaybeElementRef<MaybeElement>[]>,
    },
  },
  setup(props, { emit }) {
    const modalRef = ref<HTMLDivElement>()

    const wrapperStyle = computed(() => ({
      paddingTop: `${props.nudgeBottom}${containsCssUnit(props.nudgeBottom) ? '' : 'px'}`,
      paddingRight: `${props.nudgeLeft}${containsCssUnit(props.nudgeLeft) ? '' : 'px'}`,
      paddingBottom: `${props.nudgeTop}${containsCssUnit(props.nudgeTop) ? '' : 'px'}`,
      paddingLeft: `${props.nudgeRight}${containsCssUnit(props.nudgeRight) ? '' : 'px'}`,
    }))

    const contentStyle = computed(() => ({
      maxHeight: `${props.maxHeight}${containsCssUnit(props.maxHeight) ? '' : 'px'}`,
      minHeight: `${props.minHeight}${containsCssUnit(props.minHeight) ? '' : 'px'}`,
      maxWidth: props.maxWidth
        ? `${props.maxWidth}${containsCssUnit(props.maxWidth) ? '' : 'px'}`
        : undefined,
      minWidth: props.minWidth
        ? `${props.minWidth}${containsCssUnit(props.minWidth) ? '' : 'px'}`
        : undefined,
    }))

    onClickOutside(
      modalRef,
      () => {
        if (!props.clickOutsideToClose) {
          return
        }
        emit('update:open', false)
        emit('click:outside')
      },
      { ignore: props.ignoreClickOutsideFrom },
    )

    const onKeyDown = (event: KeyboardEvent): void => {
      if (!props.open || event.key !== 'Escape' || !props.escToClose) {
        return
      }

      event.preventDefault()
      event.stopPropagation()
      emit('keydown:esc')
    }

    onMounted(() => {
      document.addEventListener('keydown', onKeyDown, true)
    })

    onBeforeUnmount(() => {
      document.removeEventListener('keydown', onKeyDown, true)
    })

    return {
      // needed by onClickOutside
      modalRef,
      wrapperStyle,
      contentStyle,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/uiKit/assets/borders.scss';
@import '@/assets/styles/shared/scrollbarV2';

.darwin-dialog {
  position: absolute;
  display: flex;
  align-items: center;
  @include fullsize;
  justify-content: center;
  flex: 0 1 auto;
  z-index: 1;

  &--scrollable {
    @include scrollbarV2;
  }

  &--with-overlay {
    &::before {
      content: '';
      position: absolute;
      @include fullsize;
      background-color: var(--color-blanket-default);
    }
  }

  &--with-no-overlay {
    pointer-events: none;
  }

  &__content {
    display: flex;
    position: relative;
    max-height: 90vh;
    max-width: 90vw;
    border-radius: $borderRadius16;
    box-shadow: $effectShadowsLG;
    background-color: var(--color-surface-default);
    pointer-events: all;
  }
}
</style>
