import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'

import { isErrorResponse, parseError, type ParsedError } from '@/backend/error'
import { Socket, type Channel } from '@/backend/socket'
import { TimerStatus, useWorkviewTimerStore } from './useWorkviewTimerStore'
import { V3ChannelEvent } from './type'
import { useFeatureFlagsStore } from '@/pinia/useFeatureFlagsStore'

export const WorkviewTrackerStatus = {
  UNJOINED: 'unjoined',
  JOINED: 'joined',
  ERROR: 'error',
} as const

export type WorkviewTrackerStatus =
  (typeof WorkviewTrackerStatus)[keyof typeof WorkviewTrackerStatus]

type ActionResult = { error: { message: string } } | ParsedError | undefined
type ChannelResolveResult =
  | { channel: null; error: { message: string } }
  | { channel: Channel; error: null }
type JoinChannelPayload = { topic: string }

export type AutomationAction = {
  action_type: 'clicker' | 'sam'
  model_id: string | null
}
type ChannelResult = { error: { message: string } } | Channel

/**
 * `WORKLOG_V3_ENABLED` (Boolean)`
 * Influences, together with the `work_log_version` as an OR operation,
 * which communication channels (V2 and/or V3) are joined for time tracking;
 * This has no effect on the UI/UX, only on which WS messages are sent.
 *
 * `work_log_version ('2'|'3')`
 * - True, the workview operates as V3, eg: pausing/unpausing on pan/zoom.
 * - False, the workview operates as V2, eg: pausing/unpausing on annotation creation
 *
 * WORKLOG_V3_ENABLED   work_log_version   Workview Behavior   Joining WS Channels
 * -------------------  ----------------   -----------------   -----------------------------
 * False                '2'                V2                  V2 only
 * False                '3'                V3                  V2 + V3
 * True                 '2'                V2                  V2 + V3
 * True                 '3'                V3                  V2 + V3
 */

export const useWorkviewV3TrackerStore = defineStore('workviewTrackerV3', () => {
  const featureStore = useFeatureFlagsStore()
  const workviewTimerStore = useWorkviewTimerStore()

  const worklogVersion = ref<string | undefined>('3')

  // The socket channel topic, initialised upon joining a channel
  const topic = ref<string | null>(null)
  /**
   * In workflows, there is no channel being joined until the item is assigned
   * or auto-self-assigned and there's a stage id.
   *
   * Due to that, when running clicker for the first time on an unassigned item,
   * it will not be able to report activity, since there's no stage-based topic
   * to report to.
   *
   * This issue is circumvented by setting this flag on the state.
   * When the channel is resolved and joined, if this flag is active, a single
   * workview_v3:automation_action message will be sent.
   */
  const pendingAutomationAction = ref<AutomationAction>()
  // The current socket status, starts as unjoined
  const status = ref<WorkviewTrackerStatus>(WorkviewTrackerStatus.UNJOINED)

  const isWorklogVersion2 = computed(() => worklogVersion.value === '2')

  const isWorklogVersion3 = computed(() => worklogVersion.value === '3')

  const shouldSendWSMessages = computed(
    () => featureStore.featureFlags.WORKLOG_V3_ENABLED || isWorklogVersion3.value,
  )

  /**
   * Set the worklogVersion coming from the DB
   */
  const setWorklogVersion = (value?: string): void => {
    worklogVersion.value = value
  }

  /**
   * Resolve the socket connection joining the specified topic
   * and idempotently resolves the correct channel for the specified topic.
   * Updates join status and topic in state if they have changed.
   */
  const resolveChannel = async (topic: string): Promise<ChannelResolveResult> => {
    try {
      const { channel } = await Socket.connectAndJoin(topic)

      status.value = WorkviewTrackerStatus.JOINED

      channel.onError((error) => {
        status.value = WorkviewTrackerStatus.ERROR
        console.warn(`Channel with topic '${topic}' received onError`, error)
      })

      return { channel, error: null }
    } catch {
      console.warn('Failed to connect and join topic', topic)
      status.value = WorkviewTrackerStatus.ERROR
      return {
        channel: null,
        error: { message: 'Work tracking not available' },
      }
    }
  }

  /**
   * Report user manual activity
   */
  const reportActivity = async (): Promise<ActionResult> => {
    // Topic may be undefined if the workview item changes quickly (e.g., via hotkeys).
    // As this is a concurrency issue, it's fine to return early until the topic is defined.
    if (!topic.value) {
      console.warn('[Report V3] returning as topic is NOT defined')
      return
    }

    const { channel, error } = await resolveChannel(topic.value)
    if (error) {
      console.warn('Error resolving channel in reportActivity', error)
      return { error }
    }

    try {
      await Socket.pushPromise(channel, V3ChannelEvent.ACTIVITY, {})
    } catch (error) {
      if (!isErrorResponse(error)) {
        throw error
      }
      console.warn('Failed reporting activity', error)
      return parseError(error)
    }

    // If `work_log_version` is 2 then the workview should still behave as V2,
    // while still sendin v3 WS messages under the hood
    if (isWorklogVersion2.value) {
      return
    }

    workviewTimerStore.tickTimer()
  }

  /**
   * Auomation actions are counted and billed for differently than regular actions,
   * so they need to be reported and tracked separately.
   *
   * Automation actions are usages of the models that result in a new annotation, so for example,
   * the first bounding box draw with the clicker tool, but not additional clicks.
   */
  const reportAutomationAction = async (payload: AutomationAction): Promise<ActionResult> => {
    if (!topic.value) {
      pendingAutomationAction.value = payload
      return
    }

    const { channel, error } = await resolveChannel(topic.value)
    if (error) {
      console.warn('Failed resolving channel when reporting automation action', error)
      return { error }
    }

    try {
      await Socket.pushPromise(channel, V3ChannelEvent.AUTOMATION_ACTION, payload)
    } catch (error) {
      if (!isErrorResponse(error)) {
        throw error
      }
      console.warn('Failed reporting automation action', error)
      return parseError(error)
    }

    // If `work_log_version` is 2 then the workview should still behave as V2,
    // while still sendin v3 WS messages under the hood
    if (isWorklogVersion2.value) {
      return
    }

    workviewTimerStore.tickTimer()
  }

  /**
   * Reset to the initial state
   */
  const resetState = (): void => {
    workviewTimerStore.resetTimer()
    topic.value = null
    pendingAutomationAction.value = undefined
    status.value = WorkviewTrackerStatus.UNJOINED
  }

  /**
   * Performs initial join on the workview channel, setting the topic and binding to events
   */
  const joinChannel = async (payload: JoinChannelPayload): Promise<ChannelResult> => {
    if (!shouldSendWSMessages.value) {
      return {
        error: {
          message:
            'Both `WORKLOG_V3_ENABLED` and `work_log_version=3` must be true to join reports V3 channel',
        },
      }
    }

    if (topic.value !== payload.topic) {
      topic.value = payload.topic
    }

    const { channel, error } = await resolveChannel(topic.value)
    if (error) {
      console.warn('Failed resolving channel in joinChannel', error)
      return { error }
    }

    channel.on(V3ChannelEvent.TIME, ({ time }: { time: number }) => {
      if (isWorklogVersion2.value) {
        return
      }

      if (workviewTimerStore.timerStatus !== TimerStatus.STOPPED) {
        return
      }

      // There is a chance that we might receive old messages from WS, therefore we need
      // to check if the received message is from the current topic
      if (channel.topic !== topic.value) {
        return
      }

      workviewTimerStore.setTimeInSeconds(time)
      workviewTimerStore.tickTimer()
    })

    if (pendingAutomationAction.value) {
      await reportAutomationAction(pendingAutomationAction.value)
    }

    return channel
  }

  /**
   * Leaves currently connected-to channel
   */
  const leaveChannel = async (): Promise<void> => {
    if (!shouldSendWSMessages.value) {
      return
    }

    if (!topic.value) {
      console.warn('A topic is required to leave the channel')
      return
    }

    try {
      await Socket.leave(topic.value)
    } catch (error) {
      console.warn('Failed leaving channel', error)
    } finally {
      resetState()
    }
  }

  /**
   * Pause the workview timer
   */
  const pause = async (): Promise<ActionResult> => {
    if (!topic.value) {
      console.warn('A topic is required to pause')
      return
    }

    const { channel, error } = await resolveChannel(topic.value)
    if (error) {
      return { error }
    }

    try {
      await Socket.pushPromise(channel, V3ChannelEvent.PAUSE, {})
    } catch (error) {
      if (!isErrorResponse(error)) {
        throw error
      }
      console.warn('Failed pausing', error)
      return parseError(error)
    }

    // If `work_log_version` is 2 then the workview should still behave as V2,
    // while still sendin v3 WS messages under the hood
    if (isWorklogVersion2.value) {
      return
    }

    workviewTimerStore.pauseTimer()
  }

  /**
   * Unpause the workview timer
   */
  const unpause = async (): Promise<ActionResult> => {
    if (!topic.value) {
      console.warn('A topic is required to unpause')
      return
    }

    const { channel, error } = await resolveChannel(topic.value)
    if (error) {
      console.warn('Error resolving channel in unpause', error)
      return { error }
    }

    try {
      await Socket.pushPromise(channel, V3ChannelEvent.UNPAUSE, {})
    } catch (error) {
      if (!isErrorResponse(error)) {
        throw error
      }
      console.warn('Failed unpausing', error)
      return parseError(error)
    }

    // If `work_log_version` is 2 then the workview should still behave as V2,
    // while still sendin v3 WS messages under the hood
    if (isWorklogVersion2.value) {
      return
    }

    workviewTimerStore.tickTimer()
  }

  /**
   * Start/Pause the workview timer depending on its current state
   */
  const toggleTimer = async (): Promise<void> => {
    if (workviewTimerStore.timerStatus === TimerStatus.PAUSED) {
      await unpause()
      // Consider only manual unpausing as an activity because it shows
      // intention to continue annotating or reviewing
      await reportActivity()
      return
    }
    await pause()
  }

  /**
   * Signal the workview idle state change
   */
  watch(
    () => workviewTimerStore.isIdlePause,
    (value) => (value ? pause() : unpause()),
    { immediate: true },
  )

  return {
    worklogVersion,
    status,
    topic,
    setWorklogVersion,
    resolveChannel,
    joinChannel,
    leaveChannel,
    reportActivity,
    reportAutomationAction,
    unpause,
    pause,
    toggleTimer,
    resetState,
  }
})
