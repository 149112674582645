import { defineStore } from 'pinia'
import { ref, watch } from 'vue'

import { isErrorResponse, parseError, type ParsedError } from '@/backend/error'
import { Socket, type Channel } from '@/backend/socket'
import { TimerStatus, useWorkviewTimerStore } from './useWorkviewTimerStore'
import { useTeamStore } from '@/pinia/useTeamStore'
import { V2ChannelEvent } from './type'
import type { AutomationAction } from './useWorkviewV3TrackerStore'

export const WorkviewTrackerStatus = {
  UNJOINED: 'unjoined',
  JOINED: 'joined',
  ERROR: 'error',
} as const

export type WorkviewTrackerStatus =
  (typeof WorkviewTrackerStatus)[keyof typeof WorkviewTrackerStatus]

type ActionResult = { error: { message: string } } | ParsedError | undefined
type ChannelResolveResult =
  | { channel: null; error: { message: string } }
  | { channel: Channel; error: null }
type JoinChannelPayload = { topic: string }
type ChannelResult = { error: { message: string } } | Channel

export const useWorkviewV2TrackerStore = defineStore('workviewV2Tracker', () => {
  const workviewTimerStore = useWorkviewTimerStore()

  // The socket channel topic, initialised upon joining a channel
  const topic = ref<string | null>(null)
  /**
   * In workflows, there is no channel being joined until the item is assigned
   * or auto-self-assigned and there's a stage id.
   *
   * Due to that, when running clicker for the first time on an unassigned item,
   * it will not be able to report activity, since there's no stage-based topic
   * to report to.
   *
   * This issue is circumvented by setting this flag on the state.
   * When the channel is resolved and joined, if this flag is active, a single
   * workview:automation_action message will be sent.
   */
  const pendingAutomationAction = ref<AutomationAction>()
  // The current socket status, starts as unjoined
  const status = ref<WorkviewTrackerStatus>(WorkviewTrackerStatus.UNJOINED)

  /**
   * Resolve the socket connection joining the specified topic
   * and idempotently resolves the correct channel for the specified topic.
   * Updates join status and topic in state if they have changed.
   */
  const resolveChannel = async (topic: string): Promise<ChannelResolveResult> => {
    try {
      const { channel } = await Socket.connectAndJoin(topic)

      status.value = WorkviewTrackerStatus.JOINED

      channel.onError((error) => {
        status.value = WorkviewTrackerStatus.ERROR
        console.warn(`Channel with topic '${topic}' received onError`, error)
      })

      return { channel, error: null }
    } catch {
      console.warn('Failed to connect and join topic', topic)
      status.value = WorkviewTrackerStatus.ERROR
      return {
        channel: null,
        error: { message: 'Work tracking not available' },
      }
    }
  }

  /**
   * Report user manual activity
   */
  const reportActivity = async (): Promise<ActionResult> => {
    if (!topic.value) {
      return
    }

    const { channel, error } = await resolveChannel(topic.value)
    if (error) {
      console.warn('Error resolving channel in reportActivity', error)
      return { error }
    }

    try {
      await Socket.pushPromise(channel, V2ChannelEvent.ACTIVITY, {})
    } catch (error) {
      if (!isErrorResponse(error)) {
        throw error
      }
      console.warn('Failed reporting activity', error)
      return parseError(error)
    }

    workviewTimerStore.tickTimer()
  }

  /**
   * Auomation actions are counted and billed for differently than regular actions,
   * so they need to be reported and tracked separately.
   *
   * Automation actions are usages of the models that result in a new annotation, so for example,
   * the first bounding box draw with the clicker tool, but not additional clicks.
   */
  const reportAutomationAction = async (payload: AutomationAction): Promise<ActionResult> => {
    if (!topic.value) {
      pendingAutomationAction.value = payload
      return
    }

    const { channel, error } = await resolveChannel(topic.value)
    if (error) {
      console.warn('Failed resolving channel when reporting automation action', error)
      return { error }
    }

    try {
      await Socket.pushPromise(channel, V2ChannelEvent.AUTOMATION_ACTION, payload)
    } catch (error) {
      if (!isErrorResponse(error)) {
        throw error
      }
      console.warn('Failed reporting automation action', error)
      return parseError(error)
    }

    workviewTimerStore.tickTimer()
  }

  /**
   * Reset to the initial state
   */
  const resetState = (): void => {
    workviewTimerStore.resetTimer()
    topic.value = null
    pendingAutomationAction.value = undefined
    status.value = WorkviewTrackerStatus.UNJOINED
  }

  /**
   * Performs initial join on the workview channel, setting the topic and binding to events
   */
  const joinChannel = async (payload: JoinChannelPayload): Promise<ChannelResult> => {
    if (topic.value !== payload.topic) {
      topic.value = payload.topic
    }

    const { channel, error } = await resolveChannel(topic.value)
    if (error) {
      console.warn('Failed resolving channel in joinChannel', error)
      return { error }
    }

    channel.on(V2ChannelEvent.TIME, ({ time }: { time: number }) => {
      // TODO DAR-1584: needs to be called inline to deal with team store relying on vuex store
      // can be moved out once we deal with that
      if (useTeamStore().currentTeam?.work_log_version === '3') {
        return
      }

      if (workviewTimerStore.timerStatus !== TimerStatus.STOPPED) {
        return
      }

      // There is a chance that we might receive old messages from WS, therefore we need
      // to check if the received message is from the current topic
      if (channel.topic !== topic.value) {
        return
      }

      workviewTimerStore.setTimeInSeconds(time)
      workviewTimerStore.tickTimer()
    })

    if (pendingAutomationAction.value) {
      await reportAutomationAction(pendingAutomationAction.value)
    }

    return channel
  }

  /**
   * Leaves currently connected-to channel
   */
  const leaveChannel = async (): Promise<void> => {
    if (!topic.value) {
      return
    }

    try {
      await Socket.leave(topic.value)
    } catch (error) {
      console.warn('Failed leaving channel', error)
    } finally {
      resetState()
    }
  }

  /**
   * Pause the workview timer
   */
  const pause = async (): Promise<ActionResult> => {
    if (!topic.value) {
      return
    }

    const { channel, error } = await resolveChannel(topic.value)
    if (error) {
      return { error }
    }

    try {
      await Socket.pushPromise(channel, V2ChannelEvent.PAUSE, {})
    } catch (error) {
      if (!isErrorResponse(error)) {
        throw error
      }
      console.warn('Failed pausing', error)
      return parseError(error)
    }

    workviewTimerStore.pauseTimer()
  }

  /**
   * Unpause the workview timer
   */
  const unpause = async (): Promise<ActionResult> => {
    if (!topic.value) {
      return
    }

    const { channel, error } = await resolveChannel(topic.value)
    if (error) {
      console.warn('Error resolving channel in unpause', error)
      return { error }
    }

    try {
      await Socket.pushPromise(channel, V2ChannelEvent.UNPAUSE, {})
    } catch (error) {
      if (!isErrorResponse(error)) {
        throw error
      }
      console.warn('Failed unpausing', error)
      return parseError(error)
    }

    workviewTimerStore.tickTimer()
  }

  /**
   * Start/Pause the workview timer depending on its current state
   */
  const toggleTimer = async (): Promise<void> => {
    if (workviewTimerStore.timerStatus === TimerStatus.PAUSED) {
      await unpause()
      // Consider only manual unpausing as an activity because it shows
      // intention to continue annotating or reviewing
      await reportActivity()
      return
    }
    await pause()
  }

  /**
   * Signal the workview idle state change
   */
  watch(
    () => workviewTimerStore.isIdlePause,
    (value) => (value ? pause() : unpause()),
    { immediate: true },
  )

  return {
    status,
    resolveChannel,
    joinChannel,
    leaveChannel,
    reportActivity,
    reportAutomationAction,
    unpause,
    pause,
    toggleTimer,
    resetState,
  }
})
