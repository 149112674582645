/**
 * Set the data attribute for the channes blending state
 */
export const setImageChannelsState = (
  canvas: HTMLCanvasElement | null,
  state: 'blending' | 'ready',
): void => {
  if (!canvas) {
    return
  }
  if (canvas.dataset.imageChannelsState !== state) {
    canvas.dataset.imageChannelsState = state
  }
}
