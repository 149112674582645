import { defineStore } from 'pinia'
import type { LayoutConfig, LayoutSlotChannel } from '@/modules/Editor/layout'
import { LayoutConfigType, type ViewConfig } from '@/modules/Editor/layout'
import type {
  V2DatasetItemPayload,
  V2DatasetItemWithChannelsPayload,
} from '@/store/types/V2DatasetItemPayload'
import { isLayoutV3ItemPayload } from '@/store/types/V2DatasetItemPayload'
import { useWorkviewSettingsStore } from '@/pinia/useWorkviewSettingsStore'
import { isLayoutV3 } from '@/modules/Workview/isLayoutVersion'
import type { components } from '@/backend/darwin/api'
import type { V2DatasetItemSlot } from '@/store/types/V2DatasetItemSlot'
import { computed, ref, watch } from 'vue'
import { useEditorV2 } from '@/composables/useEditorV2/useEditorV2'
import { useDatasetItemsStore } from '@/modules/Datasets/useDatasetItemsStore'
import { useFeatureFlagsStore } from '@/pinia/useFeatureFlagsStore'
import { injectVirtualSlotsForDicomMPR } from '@/modules/Workview/injectVirtualSlotsForDicomMPR'
import { useToast } from '@/uiKit/Toast/useToast'
import { setContext } from '@/services/sentry'
import type { PartialRecord } from '@/core/helperTypes'
import { ViewEvents } from '@/modules/Editor/eventBus'
import debug from 'debug'
const log = debug('useLayoutStore')

type SlotName = string

export const useLayoutStore = defineStore('layout', () => {
  const datasetItemsStore = useDatasetItemsStore()
  const workviewSettings = useWorkviewSettingsStore()
  const featuresStore = useFeatureFlagsStore()
  const { editor } = useEditorV2()
  const toast = useToast()

  const itemSlots = ref<V2DatasetItemSlot[]>([])
  const layoutConfig = ref<LayoutConfig | undefined>()
  // Holds the activable channels for the current item
  const availableChannels = ref<Record<string, LayoutSlotChannel>>({})
  // available channels by slot name
  const availableSlotChannels = ref<Record<SlotName, LayoutSlotChannel[]>>({})
  // Reference the active channels for the current item
  // Note: at the moment only 1 active channel per time is supported
  const visibleChannels = ref<LayoutSlotChannel[]>([])

  const channelsCount = computed(() => Object.entries(availableChannels.value).length)

  /*
   * This is used by layout V3 where we don't have a layout_grid from the BE.
   * It might be redundant if the BE will provide it in the future.
   */
  const getViewGridBySlotSize = (
    grid: components['schemas']['DatasetsV2.Common.ItemLayoutV3']['slots_grid'],
  ): [number, number] => {
    const rows = grid.length
    const columns = Math.max(...grid.map((row) => row.length))
    return [rows, columns]
  }

  /**
   * Returns all slots that are not base slots.
   * This is only useful for channel layouts (v3) and it's used to filter out non-base slots
   */
  const getChannelsNestedSlots = (item: V2DatasetItemPayload): string[] => {
    if (!isLayoutV3(item.layout)) {
      throw new Error('channel slots can only be computed from v3 layouts')
    }

    let nestedChannelSlots: string[] = []
    item.layout.slots_grid.forEach((slotGridRow) =>
      slotGridRow.forEach((slotGridItem) => {
        if (Array.isArray(slotGridItem) && slotGridItem.length > 1) {
          nestedChannelSlots = [...nestedChannelSlots, ...slotGridItem.slice(1)]
        }
      }),
    )

    return nestedChannelSlots
  }

  /**
   * This is used by layout V3 where we only need to consider base slots, without nested channel
   * slots.
   */
  const getItemSlotsForLayoutV3 = (item: V2DatasetItemPayload): V2DatasetItemSlot[] => {
    const nonBaseChannels = getChannelsNestedSlots(item)

    return item.slots.filter(({ slot_name }) => !nonBaseChannels.includes(slot_name))
  }

  const getItemLayoutV2Config = (item: V2DatasetItemPayload): LayoutConfig => {
    if (isLayoutV3(item.layout)) {
      throw new Error('tried to get layout config from a version 3 layout')
    }

    injectVirtualSlotsForDicomMPR(item, featuresStore.featureFlags)

    const layout:
      | components['schemas']['DatasetsV2.Common.ItemLayoutV1']
      | components['schemas']['DatasetsV2.Common.ItemLayoutV2'] = item.layout

    const views = item.slots
      .toSorted((a, b) => {
        const indexA = layout?.slots?.indexOf(a.slot_name) || 0
        const indexB = layout?.slots?.indexOf(b.slot_name) || 0
        return indexA - indexB
      })
      .map<ViewConfig>((file) => ({
        file,
        item,
        initialFrameIndex: workviewSettings.multiSlotFrameIndexes[file.slot_name],
      }))

    return {
      defaultSlot: workviewSettings.activeSlotName || undefined,
      type: item.layout?.type || LayoutConfigType.SIMPLE,
      layout_shape: item.layout?.version === 2 ? item.layout?.layout_shape : undefined,
      slots: item.layout?.version === 2 ? item.layout?.slots : undefined,
      views,
    }
  }

  const isChannelVisible = (channelId: string): boolean =>
    !!visibleChannels.value.some((c) => c.id === channelId)

  /** Adds a channel to the current image by blending it in **/
  const showChannel = (channelId: string): void => {
    const channel = availableChannels.value[channelId]
    if (!channel) {
      return
    }
    if (visibleChannels.value.find((c) => c.id === channelId)) {
      // Channel already visible
      return
    }
    visibleChannels.value.push(channel)
  }

  /** Hides a channel from the editor */
  const hideChannel = (channelId: string): void => {
    visibleChannels.value = visibleChannels.value.filter((c) => c.id !== channelId)
  }

  const hideAllChannels = (): void => {
    visibleChannels.value = []
  }

  /**
   * Toggles the visibility of a channel.
   * Force = true is used to force the visibility of a channel, when we also want to notify the app
   * of the change (`showChannel` is used internally and doesn't emit events)
   */
  const toggleChannel = (channelId: string, forceShow = false): void => {
    isChannelVisible(channelId) && !forceShow ? hideChannel(channelId) : showChannel(channelId)

    const activeViewId = editor.value?.activeView.id
    if (!activeViewId) {
      return
    }
    const channel = availableChannels.value[channelId]
    if (!channel) {
      return
    }
    ViewEvents.activeChannelsChange.emit(
      { viewId: activeViewId },
      visibleChannels.value.map((c) => c.file.slot_name),
    )
  }

  /** Initialise all channels to the layout **/
  const initChannels = (item: V2DatasetItemPayload, slotGridItem: string[]): void => {
    slotGridItem.forEach((channelName: string, index: number) => {
      const channelSlot = item.slots.find((s) => s.slot_name === channelName)
      if (!channelSlot) {
        // If the channel slot can't be found, it means the setLayout API was used with names that
        // are not matching the actual slots.
        // We can't therefore render the view, and we should stop and show an error
        // This will result in the app being usable, but with a constant spinner in the view
        toast.error({
          meta: { title: `Slot ${channelName} can't be loaded as it's misconfigured` },
          duration: 15_000,
        })
        setContext('context', {
          name: 'slot_misconfigured',
          layoutVersion: '3',
          slotName: channelName,
        })
        return
      }

      const slotName = slotGridItem[0]
      const channel: LayoutSlotChannel = {
        id: channelName,
        index,
        file: channelSlot,
        slotName,
        initialFrameIndex: workviewSettings.multiSlotFrameIndexes[slotName],
      }

      availableChannels.value[channelName] = channel
      if (!availableSlotChannels.value[slotName]) {
        availableSlotChannels.value[slotName] = []
      }
      availableSlotChannels.value[slotName].push(channel)
    })
  }

  /*
   * Layout v3 is quite different from v1/2 as it supports three dimensional slots.
   * Here is an example:
   * ```
   * slots_grid: [
   *   ['slot1', 'slot2', ['slot3']],
   *   ['slot4', ['slot5', 'slot5-channel1', 'slot5-channel2', 'slot5-channel3'], 'slot6'],
   * ]
   * ```
   * The above example will produce a layout 2 x 3, the first row has 3 slots with no channels,
   * the second row has again 3 slots, but the second has 3 channels (on top of the base slot).
   *
   * Note: we initialize the availableChannels ref as a side-effect of this metho to optimize performance
   */
  const getItemLayoutV3Config = (item: V2DatasetItemWithChannelsPayload): LayoutConfig => {
    if (!isLayoutV3(item.layout)) {
      throw new Error('tried to get layout 3 config from a version 1/2 layout')
    }

    const layout = item.layout
    const channels: PartialRecord<string, V2DatasetItemSlot[]> = {}

    // Initialise channels for each layout slot
    item.layout.slots_grid.forEach((slotGridRow: (string | string[])[]): ViewConfig | void => {
      slotGridRow.forEach((slotGridItem) => {
        if (typeof slotGridItem === 'string' || slotGridItem.length === 1) {
          // When the slot is of type string, or has only 1 element, then it has no channels
          return
        }
        initChannels(item, slotGridItem)
        // Populate list of channels for each row slot
        const baseChannelSlot = slotGridItem[0]
        channels[baseChannelSlot] = item.slots.filter((slot) =>
          slotGridItem.includes(slot.slot_name),
        )
      })
    })

    const views: LayoutConfig['views'] = []
    const channelSlots = getChannelsNestedSlots(item)
    // Create a list of base slot views, including those not directly specified in the layout
    item.slots.map((slot) => {
      if (channelSlots.includes(slot.slot_name)) {
        return
      }
      slot.channels = channels[slot.slot_name]
      views.push({
        file: slot,
        item,
        initialFrameIndex: workviewSettings.multiSlotFrameIndexes[slot.slot_name],
      })
    })

    return {
      defaultSlot: workviewSettings.activeSlotName || undefined,
      type: LayoutConfigType.GRID,
      layout_shape: getViewGridBySlotSize(layout.slots_grid),
      slots: itemSlots.value.map(({ slot_name }) => slot_name),
      views,
    }
  }

  const onLayoutChange = (): void => {
    // PLAYBACK DEBUG
    log('watch datasetItemsStore.currentItem and editor.value?.viewsList', {
      datasetItemsStore: datasetItemsStore.currentItem,
      viewList: editor.value?.viewsList,
    })

    const item = datasetItemsStore.currentItem
    if (!item) {
      return
    }

    // clear available channels
    availableChannels.value = {}
    availableSlotChannels.value = {}

    // important to initialize itemSlots before as these will be used
    // to calculate the layout config as well
    itemSlots.value = isLayoutV3ItemPayload(item) ? getItemSlotsForLayoutV3(item) : item.slots

    layoutConfig.value = isLayoutV3ItemPayload(item)
      ? getItemLayoutV3Config(item)
      : getItemLayoutV2Config(item)

    const hasChannelsAndSupported =
      editor.value?.activeView?.supportsChannels &&
      Object.entries(availableChannels.value).length > 0

    /**
     * It's important to call `showChannel` so that the store knows the first channel is active,
     * and using the `Ctrl + T` shortcut will correctly start cycling through the other active
     * channels.
     * `showChannel` is not emitting events, so the editor won't do any extra render.
     */
    if (hasChannelsAndSupported) {
      // If there are channels, we need to show the first one by default
      const firstChannel = Object.values(availableChannels.value)[0]
      showChannel(firstChannel.id)
    }
  }

  /** The layout config and item slots are re-calculated on item id and views list change */
  watch(
    () => datasetItemsStore.currentItem,
    (newItem, oldItem) => {
      // The reactivity on datasetItemsStore.currentItem
      // triggers also in case of no item change
      // due to the change of itemsMap in datasetItemsStore
      // but we're only interested in "real" item changes here,
      // so we compare the ids of the new/old items and we also
      // compare all the slots associated with the item.
      // Notice that using datasetItemsStore.currentItemId
      // would not work in this case as its reactivity
      // is different from datasetItemsStore.currentItem
      // (it relies on the calls to itemsStore.setCurrentItem)
      const areSlotsIdentical =
        newItem?.slots.length === oldItem?.slots.length &&
        newItem?.slots.every((slot) => oldItem?.slots.some((oldSlot) => oldSlot.id === slot.id))

      if (newItem?.id === oldItem?.id && areSlotsIdentical) {
        return
      }

      onLayoutChange()
    },
    { immediate: true },
  )

  watch(() => editor.value?.viewsList, onLayoutChange, { immediate: true })

  return {
    itemSlots,
    layoutConfig,
    availableChannels,
    availableSlotChannels,
    visibleChannels,
    channelsCount,

    isChannelVisible,
    showChannel,
    hideChannel,
    hideAllChannels,
    toggleChannel,
  }
})
